<button
  [type]="data().type"
  [ngClass]="buttonClasses"
  [disabled]="disabled()"
  [class.isLoading]="isLoading()"
>
  {{ data().text }}

  @if (isLoading()) {
    <p-progressSpinner
      class="loader"
      styleClass="w-14px h-14px"
      strokeWidth="2"
      animationDuration=".5s"
    />
  }
</button>
