import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy, Component, input,
} from '@angular/core';
import { ButtonAppearance } from '@shared/core/enums/button-appearance.enum';
import { Button } from '@shared/core/interfaces/button.interface';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [NgClass, NgIf, ProgressSpinnerModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  public data = input.required<Button>();

  public disabled = input<boolean>();

  public isLoading = input<boolean>(false);

  get buttonClasses() {
    switch (this.data()?.appearance) {
      case ButtonAppearance.PRIMARY:
        return 'button button-primary';
      case ButtonAppearance.SECONDARY:
        return 'button button-secondary';
      default:
        return 'button';
    }
  }
}
